import moment from "moment";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Input } from "../ui/input";

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../authConfig";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";

import { useEffect, useState } from "react";
import { GMUDInformationInterface } from "src/data/approval";
import ClientsChangesTabsDialog from "./clients-changes-tabs-dialog";

const formatArrayDate = (date: number[]) => {
  return moment(
    new Date(date[0], date[1] - 1, date[2], date[3], date[4]),
  ).format("DD/MM/YYYY HH:mm");
};

export default function ClientsApprovalDialog({
  gmudId,
  gmudType,
  loading,
  onClose,
}: {
  gmudId: string;
  gmudType: string;
  loading: boolean;
  onClose: () => void;
}) {
  const [loaded, setLoaded] = useState(false);
  const [information, setInformation] =
    useState<GMUDInformationInterface | null>(null);

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const [tab, setTab] = useState(0);

  const { inProgress, instance, accounts } = useMsal();

  const handleGetGMUD = async (id: string, type: string) => {
    const response = await execute(
      "POST",
      `${protectedResources.approvals.approvalListByIdEndpoint}`,
      {
        id,
        type,
      },
    );

    setLoaded(true);

    if (response) {
      setInformation(response);
    }

    console.log(response);
  };

  useEffect(() => {
    console.log("into useEffect changes list");
    console.log(
      gmudId,
      gmudType,
      !loaded,
      inProgress === InteractionStatus.None,
    );
    (async () => {
      if (
        gmudId &&
        gmudType &&
        !loaded &&
        inProgress === InteractionStatus.None
      ) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: protectedResources.users.scopes,
        };
        try {
          const accessToken =
            await instance.acquireTokenSilent(accessTokenRequest);
          localStorage.setItem("backendAccessToken", accessToken.accessToken);
          await handleGetGMUD(gmudId, gmudType);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            try {
              const accessToken =
                await instance.acquireTokenSilent(accessTokenRequest);
              localStorage.setItem(
                "backendAccessToken",
                accessToken.accessToken,
              );
              await handleGetGMUD(gmudId, gmudType);
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    })();
  }, [instance, accounts, inProgress, gmudId, gmudType, loaded]);

  console.log("information");
  console.log(information);

  return (
    <Dialog
      onOpenChange={(value) => {
        if (!value) onClose();
      }}
      open={true}
    >
      <DialogContent className="max-w-min  max-h-full overflow-scroll">
        <DialogHeader>
          <DialogTitle>
            {information?.titulo_mudanca || "Carregando..."}
          </DialogTitle>
        </DialogHeader>
        {!information ? (
          <div className="flex justify-center items-center h-96">
            <h1 className="text-primary">Carregando...</h1>
          </div>
        ) : (
          <>
            <div className="divide-y space-y-2 divide-primary w-full h-full min-w-[960px]">
              <div className="flex space-x-2 py-2">
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm font-bold">ID</h2>
                  <Input
                    value={information.id_mudanca}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">Criticidade</h2>
                  <Input
                    value={information.criticidade.criticidade}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">Impacto</h2>
                  <Input
                    value={information.impactoMudanca.impacto_mudanca}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">Tipo</h2>
                  <Input
                    value={information.tipoMudanca.tipo_mudanca}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
              </div>
              <div className="flex space-x-2 pt-4">
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">Status</h2>
                  <Input
                    value={information.statusMudanca}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">
                    Data de execução
                  </h2>
                  <Input
                    value={formatArrayDate(information.dt_criacao)}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">Data de inicio</h2>
                  <Input
                    value={formatArrayDate([
                      ...information.dadosMudanca.dt_inicio,
                      ...information.dadosMudanca.hr_inicio,
                    ])}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
                <div className="w-full">
                  <h2 className="mb-1 text-primary text-sm">
                    Data prevista de conclusão
                  </h2>
                  <Input
                    value={formatArrayDate([
                      ...information.dadosMudanca.dt_final,
                      ...information.dadosMudanca.hr_final,
                    ])}
                    disabled
                    readOnly
                    className="disabled:opacity-100"
                  />
                </div>
              </div>
              <ClientsChangesTabsDialog information={information} />
              {/* <div className="flex flex-col py-2 w-full">
            <h2 className="text-primary text-sm">Plano de comunicação</h2>
            <div className="h-36 overflow-y-auto w-full">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Nome</TableHead>
                    <TableHead>Empresa</TableHead>
                    <TableHead>Email</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {information.planoComunicacoes.map((comunic) => (
                    <TableRow key={comunic.id_plano_comunicacao}>
                      <TableCell>{comunic.nome_contato}</TableCell>
                      <TableCell>{comunic.empresa}</TableCell>
                      <TableCell>{comunic.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div> */}
              {/* <div className="flex flex-col py-2 w-full">
            <h2 className="text-primary text-sm">Report final</h2>
            <Textarea
              value={information.reportFinal.report_final || ""}
              disabled
              rows={3}
              readOnly
              className="disabled:opacity-100"
            />
          </div> */}
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
