import moment from "moment";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";

import { ChangesInterface } from "src/data/changes";
import { Button } from "../ui/button";
import ClientsChangesDialog from "./clients-changes-dialog";

export function ClientsChangesTable({
  values,
  loadingDisable,
  page,
  onChangePage,
}: {
  values: ChangesInterface[];
  loadingDisable: boolean;
  loadingReset: boolean;
  page: number;
  onChangePage: (page: number) => void;
}) {
  const [openVizualize, setOpenVizualize] = useState<{
    gmudId: string;
    gmudType: string;
  } | null>(null);

  console.log(values);

  return (
    <>
      {openVizualize && (
        <ClientsChangesDialog
          onClose={() => setOpenVizualize(null)}
          gmudId={openVizualize.gmudId}
          gmudType={openVizualize.gmudType}
          loading={loadingDisable}
        />
      )}
      <div className="min-h-[720px]">
        <Table id="table-ads">
          {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
          <TableHeader>
            <TableRow>
              <TableHead></TableHead>
              <TableHead>ID</TableHead>
              <TableHead>Título</TableHead>
              <TableHead>Status</TableHead>
              {/* <TableHead>Criticidade</TableHead>
            <TableHead>Impacto</TableHead> */}
              <TableHead>Tipo</TableHead>
              <TableHead>Criação</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {values
              .sort(
                (a, b) =>
                  moment([
                    ...b.dt_criacao
                      .slice(0, 6)
                      .map((v, i) => (i === 1 ? v - 1 : v)),
                    b.dt_criacao[6] / 1e6,
                  ]).unix() -
                  moment([
                    ...a.dt_criacao
                      .slice(0, 6)
                      .map((v, i) => (i === 1 ? v - 1 : v)),
                    a.dt_criacao[6] / 1e6,
                  ]).unix(),
              )
              .map((task) => (
                <TableRow key={task.id_mudanca}>
                  <TableCell>
                    <Button
                      onClick={() =>
                        setOpenVizualize({
                          gmudId: String(task.id_mudanca),
                          gmudType: task.tipoMudanca.tipo_mudanca,
                        })
                      }
                    >
                      Vizualizar
                    </Button>
                  </TableCell>
                  <TableCell>{task.id_mudanca}</TableCell>
                  <TableCell className="font-medium">
                    {task.titulo_mudanca}
                  </TableCell>
                  <TableCell>{task.statusMudanca}</TableCell>
                  <TableCell>{task.tipoMudanca.tipo_mudanca}</TableCell>
                  <TableCell>
                    {moment([
                      ...task.dt_criacao
                        .slice(0, 6)
                        .map((v, i) => (i === 1 ? v - 1 : v)),
                      task.dt_criacao[6] / 1e6,
                    ]).format("DD/MM/yyyy HH:mm")}
                  </TableCell>
                  {/* <TableCell>{task.criticidade.criticidade}</TableCell>
                  <TableCell>{task.impactoMudanca.impacto_mudanca}</TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!values.length && !loadingDisable && (
          <div className="flex h-full items-center justify-center mt-12">
            <span>Nenhuma mudança encontrada</span>
          </div>
        )}
      </div>
      <div className="mt-4 w-full flex justify-center space-x-2">
        <Button disabled={page === 1} onClick={() => onChangePage(page - 1)}>
          Anterior
        </Button>
        <Button variant={"outline"}>{page}</Button>
        <Button
          disabled={values.length < 10}
          onClick={() => onChangePage(page + 1)}
        >
          Próximo
        </Button>
      </div>
    </>
  );
}
