import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { toast } from "sonner";
import ClientsChangesSelectStatus from "src/components/clients-changes/clients-changes-select-status";
import { ClientsChangesTable } from "src/components/clients-changes/clients-changes-table";
import { ChangesInterface } from "src/data/changes";
import { UserADInterface } from "src/data/users";
import { appRoles, protectedResources } from "../authConfig";
import { CompanyInterface } from "../data/companies";
import useFetchWithMsal from "../hooks/useFetchWithMsal";

const ClientsChangesContent = () => {
  const { error, execute, account } = useFetchWithMsal({
    scopes: protectedResources.users.scopes,
  });

  const { inProgress, instance, accounts } = useMsal();

  const isAdmin =
    instance
      .getActiveAccount()
      ?.idTokenClaims?.roles?.includes(appRoles.SysAdministrator) || false;

  console.log("isAdmin");
  console.log(isAdmin);

  const [company, setCompany] = useState<null | string>(null);
  const [approvals, setApprovals] = useState<null | ChangesInterface[]>(null);
  const [companies, setCompanies] = useState<null | CompanyInterface[]>(null);

  const [filtredItems, setFiltredItems] = useState<UserADInterface[]>([]);

  const [loading, setLoading] = useState(false);

  const [loadingDisable, setLoadingDisable] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);

  const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    "AGUARDANDO_EXECUCAO",
  );

  useEffect(() => {
    if (isAdmin && selectedStatus && !approvals) {
      handleGetApprovalList();
    } else if (!isAdmin && !approvals && selectedStatus) {
      handleGetApprovalList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, approvals, company, selectedStatus, page]);

  const handleGetApprovalList = async () => {
    console.log("handleGetApprovalList");
    setLoading(true);
    execute(
      "GET",
      isAdmin && !company
        ? protectedResources.approvals.allChangesListEndpoint +
            `?status=${selectedStatus}&page=${page}`
        : isAdmin && company
          ? protectedResources.approvals.allChangesListEndpoint +
            "/?id=" +
            company
          : protectedResources.approvals.changesListEndpoint,
      undefined,
      {
        company,
      },
    )
      .then((response) => {
        setApprovals(response);
        setFiltredItems(response);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao buscar usuários");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="px-4">
      <div className="flex justify-between space-x-2">
        <div className="w-2/6">
          <ClientsChangesSelectStatus
            value={selectedStatus}
            onSelectChange={(value: string) => {
              setApprovals(null);
              setPage(1);
              setSelectedStatus(value);
            }}
            loading={loading}
          />
        </div>

        <div className="w-3/6 flex space-x-1">
          {/* <CompanyIntegrationSelect
            onSelectChange={(value) => {
              setApprovals(null);
              setCompany(value);
            }}
          /> */}
          {/* <Button
            disabled={loading || !company}
            onClick={() => handleGetApprovalList()}
          >
            <RefreshCw className={`size-4 ${loading && "animate-spin"}`} />
          </Button> */}
        </div>
      </div>
      <ClientsChangesTable
        values={approvals || []}
        loadingDisable={loading}
        loadingReset={loadingReset}
        page={page}
        onChangePage={(page: number) => {
          setApprovals(null);
          setPage(page);
        }}
      />
    </div>
  );
};

export const ClientsChangesPage = () => {
  return <ClientsChangesContent />;
};
