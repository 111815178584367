import Select from "react-select";

const OPTIONS = [
  {
    value: "ABERTA",
    label: "Aberta",
  },
  {
    value: "AGUARDANDO_APROVACOES",
    label: "Aguardando Aprovações",
  },
  {
    value: "APROVADA",
    label: "Aprovadas",
  },
  {
    value: "CANCELADA",
    label: "Cancelada",
  },
  {
    value: "REJEITADA",
    label: "Rejeitada",
  },
  {
    value: "AGUARDANDO_EXECUCAO",
    label: "Aguardando Execução",
  },
  {
    value: "EM_EXECUCAO",
    label: "Em Execução",
  },
  {
    value: "MUDANCA_FIM_SUCESSO",
    label: "Mudança Concluida com Sucesso",
  },
  {
    value: "MUDANCA_FIM_RESSALVA",
    label: "Mudança Concluida com Ressalva",
  },
  {
    value: "MUDANCA_FIM_FALHA",
    label: "Mudança Concluida com Falha",
  },
  {
    value: "MUDANCA_ABORTADA",
    label: "Mudança Concluida com Abortada",
  },
  {
    value: "MUDANCA_ABORTADA_ROLLBACK",
    label: "Mudança Concluida com Abortada com Rollback",
  },
];

export default function ClientsChangesSelectStatus({
  value,
  onSelectChange,
  loading,
}: {
  onSelectChange: (company: string) => void;
  value: string;
  loading: boolean;
}) {
  return (
    <Select
      className="w-full"
      options={OPTIONS.sort((a, b) => a.label.localeCompare(b.label))}
      onChange={(value) => {
        if (value) onSelectChange(value.value);
      }}
      value={OPTIONS.find((option) => option.value === value)}
      placeholder="Selecione um status"
      isLoading={loading}
      isSearchable
      isClearable
    />
  );
}
