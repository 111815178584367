import moment from "moment";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { GMUDInformationInterface } from "src/data/approval";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";

const formatArrayDate = (date: number[]) => {
  return moment(
    new Date(date[0], date[1] - 1, date[2], date[3], date[4]),
  ).format("DD/MM/YYYY HH:mm");
};

export default function ClientsChangesTabsDialog({
  information,
}: {
  information: GMUDInformationInterface;
}) {
  const [tab, setTab] = useState(0);

  return (
    <div>
      <div className="flex py-2 justify-around">
        <Button
          className="w-full rounded-none rounded-l-lg"
          onClick={() => setTab(0)}
          disabled={tab === 0}
        >
          Descrição
        </Button>
        <Button
          className="w-full rounded-none"
          onClick={() => setTab(1)}
          disabled={tab === 1}
        >
          Justificativa
        </Button>
        <Button
          className="w-full rounded-none"
          onClick={() => setTab(2)}
          disabled={tab === 2}
        >
          Plano de teste
        </Button>
        <Button
          className="w-full rounded-none"
          onClick={() => setTab(3)}
          disabled={tab === 3}
        >
          Plano de retorno
        </Button>
        <Button
          className="w-full rounded-none"
          onClick={() => setTab(5)}
          disabled={tab === 5}
        >
          Tarefas
        </Button>
        <Button
          className="w-full rounded-none "
          onClick={() => setTab(6)}
          disabled={tab === 6}
        >
          Aprovadores
        </Button>
        <Button
          className="w-full rounded-none rounded-r-lg"
          onClick={() => setTab(4)}
          disabled={tab === 4}
        >
          Report Final
        </Button>
      </div>
      <div className="flex flex-col py-2 w-full h-96">
        {tab === 0 ? (
          <>
            <h2 className="mb-1 text-primary text-sm">Descrição da mudança</h2>
            <Textarea
              value={information.dadosMudanca.dsc_mudanca}
              disabled
              rows={12}
              readOnly
              className="disabled:opacity-100"
            />
          </>
        ) : tab === 1 ? (
          <>
            <h2 className="mb-1 text-primary text-sm">
              Justificativa da mudança
            </h2>
            <Textarea
              value={information.dadosMudanca.justificativa_mudanca}
              disabled
              rows={12}
              readOnly
              className="disabled:opacity-100"
            />
          </>
        ) : tab === 2 ? (
          <>
            <h2 className="mb-1 text-primary text-sm">Plano de teste</h2>
            <Textarea
              value={information.acaoPosAtividade?.plano_teste || ""}
              disabled
              rows={12}
              readOnly
              className="disabled:opacity-100"
            />
          </>
        ) : tab === 3 ? (
          <>
            <h2 className="text-primary text-sm">Plano de retorno</h2>
            <Textarea
              value={information.acaoPosAtividade?.plano_rollback || ""}
              disabled
              rows={12}
              readOnly
              className="disabled:opacity-100"
            />
          </>
        ) : tab === 4 ? (
          <>
            <h2 className="text-primary text-sm">Report Final</h2>
            <Textarea
              value={information.reportFinal?.report_final || ""}
              disabled
              rows={12}
              readOnly
              className="disabled:opacity-100"
            />
          </>
        ) : tab === 5 ? (
          <>
            <h2 className="text-primary text-sm">Tarefas</h2>
            <div className="h-96 overflow-y-auto w-full">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Nome</TableHead>
                    <TableHead>Responsável</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Data de criação</TableHead>
                    <TableHead>Data de inicio</TableHead>
                    <TableHead>Data de conclusão</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {information.atividadesMudanca.map((atividadesMudanca) => (
                    <TableRow key={atividadesMudanca.id_atividade_mudanca}>
                      <TableCell>
                        {atividadesMudanca.titulo_atividade_mudanca}
                      </TableCell>
                      <TableCell>
                        {
                          atividadesMudanca.responsavelAtividade
                            .login_responsavel_atividade
                        }
                      </TableCell>
                      <TableCell>
                        {atividadesMudanca.statusAtividade.status_atividade}
                      </TableCell>
                      <TableCell>
                        {atividadesMudanca.statusAtividade.dt_criacao &&
                          formatArrayDate(
                            atividadesMudanca.statusAtividade.dt_criacao,
                          )}
                      </TableCell>
                      <TableCell>
                        {atividadesMudanca.dt_inicio_tarefa &&
                          formatArrayDate(atividadesMudanca.dt_inicio_tarefa)}
                      </TableCell>
                      <TableCell>
                        {atividadesMudanca.dt_final_tarefa &&
                          formatArrayDate(atividadesMudanca.dt_final_tarefa)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        ) : tab === 6 ? (
          <>
            <h2 className="text-primary text-sm">Aprovadores</h2>
            <div className="h-96 overflow-y-auto w-full">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Nome</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Data de aprovação / rejeição</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {information.listaAprovadores.map((listAprovador) => (
                    <TableRow key={listAprovador.id_lista_aprovadores}>
                      <TableCell>
                        {listAprovador.aprovadores.aprovador}
                      </TableCell>
                      <TableCell>{listAprovador.aprovadores.email}</TableCell>
                      <TableCell>
                        {listAprovador.statusAprovacao &&
                        !listAprovador.dt_reprovacao
                          ? "Aprovado"
                          : listAprovador.statusAprovacao &&
                              listAprovador.dt_reprovacao
                            ? "Rejeitado"
                            : "Pendente"}
                      </TableCell>
                      <TableCell>
                        {listAprovador.dt_aprovacao
                          ? formatArrayDate(listAprovador.dt_aprovacao)
                          : listAprovador.dt_reprovacao
                            ? formatArrayDate(listAprovador.dt_reprovacao)
                            : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
